<template>
  <div>
    <BaseDialog title="入库统计" :isshow="visible" @handleShow="
    (val) => {
      visible = val
    }
  " width="1000px" height="auto">
      <!-- 内容-->
      <div class="addform">
        <div class="margin-set">
          <el-button class="color-vice" type="primary" @click="exportTable">
            <i class="fa fa-arrow-down"></i>导出
          </el-button>
        </div>
        <div>
          <el-table class="sss" v-loading="dataListLoading" style="width: 100%;" :data="tableData" stripe border max-height="56vh">
            <el-table-column width="180" prop="name" label="名称" fixed show-overflow-tooltip>
              <template #default="scope">
                <span>{{ scope.row.drug.name }}</span>
              </template>
            </el-table-column>
            <el-table-column width="130" prop="order_no" fixed label="入库单号" />
            <el-table-column width="100" prop="batch_no" label="批号" fixed show-overflow-tooltip />
            <el-table-column width="150" property="drug.medi_spec" label="规格" show-overflow-tooltip />
            <el-table-column width="120" property="drug.approval_no" label="国药准字" show-overflow-tooltip />
            <el-table-column width="180" property="drug.company" label="厂家" show-overflow-tooltip />
            <el-table-column width="100" label="入库数量">
              <template #default="scope">
                <span>{{ scope.row.enter_num }}</span>
              </template>
            </el-table-column>
            <el-table-column width="80" label="单位">
              <template #default="scope">
                <span>{{ scope.row.drug.dict_first_unit_name }}</span>
              </template>
            </el-table-column>
            <el-table-column width="120" prop="validity_end_time" label="到期时间" show-overflow-tooltip />
            <!-- 入库单价 -->
            <el-table-column label="采购单价" width="100">
              <template #default="scope">
                <span>{{ scope.row.buy_price }}元</span>
              </template>
            </el-table-column>
            <!-- 入库总额 -->
            <el-table-column label="采购进价总额" width="130">
              <template #default="scope">
                <span>{{ (scope.row.buy_price * scope.row.enter_num).toFixed(2) }}元</span>
              </template>
            </el-table-column>
            <el-table-column width="100" label="零售单价">
              <template #default="scope">
                <span>{{ scope.row.price }}元</span>
              </template>
            </el-table-column>
            <el-table-column width="120" label="零售单价总额">
              <template #default="scope">
                <span>{{ (scope.row.price * scope.row.enter_num).toFixed(2) }}元</span>
              </template>
            </el-table-column>
            <el-table-column width="100" label="进零差额">
              <template #default="scope">
                <span>{{ (scope.row.price * scope.row.enter_num - scope.row.buy_price *
                  scope.row.enter_num).toFixed(2) }}元</span>
              </template>
            </el-table-column>
            <el-table-column width="180" prop="enter_time" label="入库时间" />
            <el-table-column width="80" property="operator.name" label="入库人" align="center" />
            <el-table-column prop="remark" label="备注" width="180" />
          </el-table>
          <div class="pagination-box">
            <el-pagination v-model:currentPage="pageData.currentPage" v-model:page-size="pageData.pageSize"
              :page-sizes="[10, 20, 50, 100]" :background="true" layout="total, sizes, prev, pager, next, jumper"
              :total="pageData.totalSum" @size-change="handleSizeChange"
              @current-change="handleCurrentChange"></el-pagination>
          </div>
        </div>
      </div>
      <el-divider />
      <div style="text-align: right">
        <el-button @click="visible=false">取消</el-button>
        <el-button type="primary" @click="visible=false">确定</el-button>
      </div>
    </BaseDialog>
  </div>
</template>

<script>
  import { toRefs, reactive, onMounted } from 'vue'
  import service from '@/utils/request'
  import { downLoadXlsx,TimestampToTime } from '@/utils/tool'
  import BaseDialog from '@/components/Dialog/index.vue'
  export default {
    components: {
      BaseDialog,
    },
    setup() {
      const state = reactive({
        visible: false,
        loading: true,
        pageData: {
          currentPage: 1,
          pageSize: 10,
          totalSum: 0,
        },
        tableData: [],
      })
      // 获取列表
      const tobodyAlert = async () => {
        state.visible = true
        state.loading = true
        let data = {
          size: state.pageData.pageSize,
          current: state.pageData.currentPage,
          dict_catalog_type:'160000000'
        }
        let res = await service.post('/api/store_enter_record/get_statis_list', data)
        if (res.code === 0) {
          if (res.data.total > 0) {
            state.tableData = res.data.records
            state.pageData.totalSum = res.data.total
          } else {
            state.tableData = []
            state.pageData.totalSum = 0
          }
        }
        state.loading = false
      }
      // 条数改变时
      const handleSizeChange = (e) => {
        state.pageData.pageSize = e
        tobodyAlert()
      }
      // 页数改变时
      const handleCurrentChange = (e) => {
        state.pageData.currentPage = e
        tobodyAlert()
      }
      const exportTable = (row) => {
        let data = {
          dict_catalog_type:'160000000'
        }
        let time = TimestampToTime()
        let title = `科室药品入库统计${time}.xlsx` 
        downLoadXlsx('/api/store_enter_record/export_statis', data, title)
      }
      return {
        ...toRefs(state),
        tobodyAlert,
        handleSizeChange,
        handleCurrentChange,
        exportTable
      }
    },
  }
</script>

<style scoped lang="scss">
  .adviceBox {
    height: 100%;
    background: #ffffff;
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    padding: 10px;
  }

  .demo-tabs>.el-tabs__content {
    padding: 32px;
    color: #6b778c;
    font-size: 32px;
    font-weight: 600;
  }

  .display_flex {
    display: flex;
    justify-content: space-between;
  }

  .search-top .w-208 {
    width: 208px;

    .searchcss {
      cursor: pointer;
    }
  }

  .faicon {
    font-size: 19px;
    margin-right: 6px;
    cursor: pointer;
  }

  .title-top {
    align-items: center;
    display: flex;
  }

  .tabclis {
    height: 32px;
    background: rgba(49, 102, 174, 0.1);
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    display: flex;
    padding: 3px;

    .tabclis_right {
      display: flex;
      padding-top: 2px;
    }
  }

  .addform {
    .el-form {
      display: flex;
      flex-wrap: wrap;

      &:deep(.el-form-item__label) {
        width: 110px !important;
        text-align: right;
      }

      &:deep(.el-input) {
        width: 200px !important;
      }

      &:deep(.el-form-item) {
        width: 45% !important;
      }

      .mt_4 {
        &:deep(.el-input) {
          width: 100px !important;
        }
      }

      .parienttag {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        .tag {
          margin-right: 8px;
          margin-bottom: 8px;
          color: #409eff;
          background-color: #ecf5ff;
          height: 20px;
          line-height: 20px;
          border-radius: 3px;
          text-align: center;
          font-size: 12px;
          padding: 0 10px;
          cursor: pointer;
        }

        .tags {
          margin-right: 8px;
          margin-bottom: 8px;
          background: #409eff;
          color: #ffffff;
          border-radius: 3px;
          height: 20px;
          line-height: 20px;
          text-align: center;
          font-size: 12px;
          padding: 0 10px;
          cursor: pointer;
        }
      }
    }
  }

  .pagination-box {
    &:deep(.el-input__inner) {
      padding-right: 15px !important;
    }
  }

  .w-144 {
    margin-top: 20px;
  }

  .stop {
    background-color: #f4a939;
  }

  .modify {
    background-color: #3166ae;
  }

  .del {
    background-color: #ffecec;
    color: #ff6b6b;
    font-weight: 700;
  }

  .top {
    height: 35px;
    margin: 8px 0;
  }

  .margin-set {
    padding: 10px;
    float: right;
  }
</style>